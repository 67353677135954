<template>
  <div class="gpt-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" size="small" label-width="180px">
      <el-form-item :label="$t('gpt.desc')">
        <el-input v-model="form.desc" size="small" style="width: 30%"></el-input>
      </el-form-item>
      <el-form-item :label="$t('gpt.sex')">
        <el-radio-group v-model="form.sex" @change="() => { getOptions('age'); getOptions('style') }">
          <el-radio v-for="item in sexList" :key="item.key" :label="item.key">
            {{ sexFilter(item.key) }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('gpt.age')">
        <el-select v-model="form.age" filterable>
          <el-option v-for="item in ageList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('gpt.style')">
        <el-select v-model="form.style" filterable @change="getOptions('trendPoint')">
          <el-option v-for="item in styleList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-form-gutter" :label="$t('gpt.trendPoint')">
        <el-select v-model="form.trendPoint" filterable>
          <el-option v-for="item in trendPointList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-form-gutter" :label="$t('gpt.season')">
        <el-select v-model="form.season" filterable>
          <el-option v-for="item in seasonList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-form-gutter" :label="$t('gpt.pose')">
        <el-select v-model="form.pose" filterable>
          <el-option v-for="item in poseList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-form-gutter" :label="$t('gpt.place')">
        <el-select v-model="form.place" filterable>
          <el-option v-for="item in placeList" :key="item.key" :label="item.key" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="search-form-gutter" :label="$t('gpt.imageCount')">
        <el-input-number v-model="form.imageCount" :min="1" :max="10"></el-input-number>
      </el-form-item>
      <el-form-item class="search-form-gutter">
        <el-button type="primary" icon="el-icon-s-promotion" @click="onSubmit">{{ $t("common.submit") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createTask, fetchOptions } from "@/api/gpt";
import AiBreadcrumb from "@/components/AiBreadcrumb";

export default {
  components: {
    AiBreadcrumb,
  },
  data() {
    return {
      poseList: [],
      sexList: [],
      ageList: [],
      styleList: [],
      placeList: [],
      seasonList: [],
      trendPointList: [],
      form: {
        desc: "",
        pose: "",
        sex: "",
        age: "",
        style: "",
        place: "",
        season: "",
        trendPoint: "",
        imageCount: 5,
      },
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      createTask({
        ...this.form,
        category: "user"
      })
        .then((response) => {
          this.loading = false;
          if (response.success) {
            this.$notify({
              title: this.$t("common.saveSucc"),
              message: this.$t("common.indirecting"),
              type: "success",
            });
            let suffix = response.result && response.result.id ? "/" + response.result.id : ""
            this.$router.push("/services/gpt/creator" + suffix).catch(() => { });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getOptions(type) {
      let data = { "type": type }
      if (type === 'age') {
        data["sex"] = this.form.sex;
      } else if (type === 'style') {
        data["sex"] = this.form.sex;
      } else if (type === 'trendPoint') {
        data["style"] = this.form.style;
        data["sex"] = this.form.sex;
      }
      fetchOptions(data).then(response => {
        if (response.success) {
          if (type === "sex") {
            this.sexList = response.result && response.result.items
              ? response.result.items : [];
            // 反转为 "W-M-C"
            this.sexList = this.sexList.reverse();
            this.form.sex = this.sexList.length > 0 ? this.sexList[0].key : "";
            if (this.form.sex) {
              this.getOptions("age");
              this.getOptions("style");
            }
          } else if (type === "age") {
            this.ageList = response.result && response.result.items
              ? response.result.items : [];
            this.form.age = this.ageList.length > 0 ? this.ageList[0].key : "";
          } else if (type === "style") {
            this.styleList = response.result && response.result.items
              ? response.result.items : [];
            this.form.style = this.styleList.length > 0 ? this.styleList[0].key : "";
            if (this.form.style) {
              this.getOptions("trendPoint");
            }
          } else if (type === "trendPoint") {
            this.trendPointList = response.result && response.result.items
              ? response.result.items : [];
            this.form.trendPoint = this.trendPointList.length > 0 ? this.trendPointList[0].key : "";
          } else if (type === "place") {
            this.placeList = response.result && response.result.items
              ? response.result.items : [];
            this.form.place = this.placeList.length > 0 ? this.placeList[0].key : "";
          } else if (type === "season") {
            this.seasonList = response.result && response.result.items
              ? response.result.items : [];
            this.form.season = this.seasonList.length > 0 ? this.seasonList[0].key : "";
          } else if (type === "pose") {
            this.poseList = response.result && response.result.items
              ? response.result.items : [];
            this.form.pose = this.poseList.length > 0 ? this.poseList[0].key : "";
          }
        }
      })
    },
    sexFilter(sex) {
      const sexMap = {
        W: "women",
        M: "man",
        C: "child",
      }
      if (sexMap[sex]) {
        return this.$t('gpt.' + sexMap[sex])
      }
      return sex
    }
  },
  mounted() {
    this.getOptions("sex");
    this.getOptions("pose");
    this.getOptions("place");
    this.getOptions("season");
  }
};
</script>

<style lang="scss" scoped>
.gpt-new {
  .form {
    margin-top: 25px;
  }
}
</style>